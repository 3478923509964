@use "sass:math";

@function em($pixels, $context: 16px) {
  @return #{math.div($pixels, $context)}em;
}

@function rem($pixels, $context: 16px) {
  @return #{math.div($pixels,$context)}rem
}


@mixin from($min_width) {
  @media screen and (min-width: $min_width) {
    @content;
  }
}

@mixin to($max_width) {
  @media screen and (max-width: $max_width) {
    @content;
  }
}

@mixin from-to($min_width, $max_width) {
  @media screen and (min-width: $min_width) and (max-width: $max_width) {
    @content;
  }
}

@mixin field-name {
  font-size: rem(12px);
  line-height: 1.3;
  color: #727272;
  text-transform: uppercase;
}
