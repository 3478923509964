@font-face {
  font-family: "BlissPro";
  src: url("https://cdn.eto.travel/i/fonts/BlissPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BlissPro";
  src: url("https://cdn.eto.travel/i/fonts/BlissPro-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BlissPro";
  src: url('https://cdn.eto.travel/i/fonts/BlissPro-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}


[class^="icon-"], [class*=" icon-"] {
  width: 1.2rem; height: 1.2rem;
  background: transparent no-repeat 0 0;
  background-size: contain;
}

.icon-calendar {
  background-image: url("../images/calendar.svg");
}


.icon-bed {
  background-image: url("../images/bed.svg");
}

.icon-fly-up {
  background-image: url("../images/fly-up.svg");
}

.icon-fly-down {

  background-image: url("../images/fly-up.svg");
  transform: rotate(-90deg);
}


.icon-food {
  background-image: url("../images/food.svg");
}


.icon-arrow-down {
  background-image: url("../images/arrow-down.svg");
}  

