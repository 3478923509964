.header {
  padding: 20px 0;

  &__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }

  &__logo {
    display: flex;
    width: 230px;
    margin-right: 20px;
    margin-bottom: 20px;
    img {width: 100%}
  }
  .manager-info {
    margin-bottom: 20px;
    &__text-box {
    }

    &__text {
      color: var(--accent);
      font-size: rem(14px);
      font-weight: 600;
      line-height: 1.6;
    }
  }
}
