$eto-root: "//cdn.eto.travel";
@import "@/assets/styles/style.scss";

.icodiv {
	display: flex;
	align-items: center;
	margin: 20px 0;


	&.success {
		border-radius: 5px;
		background: #99ff99;
	}

	.warn { color: #883a37;}

	&-ico { 
		flex-basis: 50px; flex-shrink: 0; 
		/*min-height: 50px;*/
		>svg { width: 100%; height: 32px; }
		display: flex;
	}
	&-text { flex-basis: 100%; flex-grow: 1;}
}