$eto-root: "//cdn.eto.travel";

.confirm-loa {height: 20px;}
.confirm-loa1 {height: 40px;}
.confim-big {
    margin: 20px 0;
    display: flex;
    align-items: center;
    &-l {font-size: 20px;  padding-right: 20px;}

}
.confirm-input {
    width: 120px;
    border: 1px solid #00f;
    font-size: 1rem;
    line-height: 1.62;
    padding: 6px 12px;
    box-shadow: none;
    background-color: #fff;
    border-radius: 5px;
    margin: 5px 0;
    color: #000;
    transition: .25s ease box-shadow;
    text-align: center;
    letter-spacing: 14px;
    font-size: 20px;
    appearance: textfield;
}
.confirm-h {
    font-weight: bold;
    margin-bottom: 20px;
}
