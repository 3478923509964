// footer.dis.php

.eto-stage {
  background:  #FFEFEB;
  margin: 0 0 20px 0;
  padding: 40px;
  display: flex;
  justify-content: center;
  .a {
    color: #FF5C39;
    font-weight: bold;
    font-size: rem(16px);
    &:hover {
      text-decoration: none;
    }
  }
}

.footer {
  padding: 30px 0 15px;

  @include to(540px) {
    padding: 20px 0;
  }


  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include to(540px) {
      padding: 0 20px;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    &__col {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      &:first-child {
        margin-left: 0;
      }
    }
    &__link {
      text-decoration: none;
      font-size: rem(14px);
      line-height: 1.7;
      margin-bottom: rem(5px);
      width: max-content;
      color: #0056bc;
      transition: 0.2s ease;
      will-change: color;

      &:hover {
        color: #2d2d2d;
      }
    }

  }

  &__more {
    margin-bottom: 30px;

    @include to(540px) {
      width: 100%;
    }
  }

  &__line {
    border-top: 1px solid #EBEBEB;
    margin: auto;
    display: block;
  }

  &__text-box {
    padding: 20px 0 0;
    margin-bottom: 15px;
    max-width: 600px;

    @include to(540px) {
      padding: 20px 0;
    }


    p {
      font-size: rem(12px);
      line-height: 1.6;
      margin-bottom: rem(10px);
      color: rgba(0, 0, 0, 0.4);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  &__col-title {
    font-size: rem(16px);
    font-weight: 600;
    line-height: 1.3;
    margin: 0 0 rem(10px);
  }

  &__media {
    margin-bottom: 20px;
  }


  &__copy {
    font-size: rem(12px);
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.4);

    @include to(540px) {
      padding: 0 15px;
    }
  }
}

.media, .payments {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__link {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__item {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__icon {
    font-size: 30px;
  }
}

.media {
  &__item {
    transition: 0.2s ease;
    width: 24px;
    height: 24px;

    &:hover {
      transform: translateY(-6px);
    }
  }
}

.payments {
  &__item {
    width: 36px;
    height: 24px;
    span {
      height: 22px;
      width: 36px;
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
    }
    &.sber {
      width: 62px;
      span {
        width: 62px;
      }
    }

  }

  &__icon {
    font-size: 21px;
  }
}
