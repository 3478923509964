html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: "BlissPro", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  background: #ffffff;
  min-height: 100vh;
  min-width: 280px;
  overflow-x: hidden;
  line-height: 1.5;
  color: #2D2D2D;
}

img {
  max-width: 100%;
}

button,
input,
label {
  font-family: "Arial", sans-serif;
  outline: none;
}


h1,h2,h3,h4,h5 {
  font-weight: 600;
}