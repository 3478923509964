.service-wrapper {
  max-width: 1100px;
  margin: auto;
}

.container {
  display: flex;
  max-width: 1170px;
  margin: auto;
  padding: 0 20px;

  @include to(992px) {
    max-width: 750px;
  }
}

.text-bold {
  font-weight: 600;
}

.btn {
  background-color: var(--accent);
  color: #fff;
  text-decoration: none;
  font-size: rem(16px);
  padding: rem(15px);
  transition: 0.2s ease;
  will-change: opacity;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  font-family: "BlissPro", "Helvetica Neue", Helvetica, sans-serif;

  &:hover {
    opacity: 0.8;
  }
  &.zel {
    background-color: #1E9E3A;
    cursor: default;
    &:hover {
      opacity: 1;
    }
  }
  &.ser {
    background-color: #aaaaaa;
    cursor: default;
    &:hover {
      opacity: 1;
    }
  }

}


.section-header {
  margin-bottom: 30px;

  @include to(992px) {
    margin-bottom: 20px;
  }
}

.page-title {
  line-height: 1.21;
  font-size: rem(38px);

  @include to(540px) {
    font-size: rem(21px);
  }
}


.link {
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  color: var(--accent);
  border: 1px solid;
  border-radius: 5px;
  font-size: rem(12px);
  text-transform: uppercase;
  font-weight: 400;
  padding: rem(9px) rem(20px);
  text-align: center;
  transition: all 0.2s ease;
  will-change: background;
  display: inline-block;
  outline: none;

  &:hover {
    background: #fff1e6;
  }
}

.wait {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px;
}

// спинер
.load-spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.load-spinner div {
  transform-origin: 32px 32px;
  animation: load-spinner 1.1s linear infinite;
}
.load-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0px;
  left: 29px;
  width: 6px;
  height: 18px;
  border-radius: 5px;
  background: #ff5c39;
}
.load-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.load-spinner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -1s;
}
.load-spinner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.9s;
}
.load-spinner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.8s;
}
.load-spinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.7s;
}
.load-spinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.6s;
}
.load-spinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.5s;
}
.load-spinner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: -0.4s;
}
.load-spinner__img {
  position: absolute;
  left: 36%;
  top: 38%;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-top-width: 8px;
  border-bottom-width: 8px;
  border-right: 14px solid #0057b7;
}
@keyframes load-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.wait-css {display: block;}
.nowrap {white-space: nowrap;}
