$eto-root: "//cdn.eto.travel";
@import "@/assets/styles/style.scss";

.g {
	margin: 30px 0;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #aaa;
	padding-bottom: 20px;
	&-h {font-weight: bold; margin: 10px 0;}
	&-line {
		display: flex; flex-direction: row;
		&-t {flex-basis: 150px; flex-shrink: 0; color: #999;}
		&-d {flex-basis: 100%; flex-shrink: 1;}
	}
	.links {
		>* { margin: 0 10px; }
	}
}